import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'

import { Archive } from '../util/types'

interface Props extends PageProps {
    data: {
        sanityArchiveSearch: {
            archives: Archive[]
            noinfo_questions: string[]
            types: {
                description: string
                name: string
            }[]
        }
    }
}

const ArchiveNoInfo: React.FC<Props> = ({ data }) => {
    const { archives, noinfo_questions, types } = data.sanityArchiveSearch

    return (
        <div>
            <Layout currentPage="archivsuche">
                <SEO title="Was Wa(h)r - Archivsuche" />
            </Layout>

            <div className="archive">
                <p className="archive-heading">Fragen an deine Familie</p>

                <div className="archive-noinfoquestions">
                    {noinfo_questions.map((q, i) => (
                        <p key={i}>{q}</p>
                    ))}
                </div>

                <p className="archive-heading">
                    Archive die vielleicht für dich interessant sein könnten:
                </p>

                {archives
                    .filter(a => a.shownoinfo)
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(({ name, url, type }, i) => (
                        <a href={url} target="_blank" key={i} className="archive-button">
                            <button>
                                {name}
                                <span>{'([{ }])'}</span>
                                <span>
                                    {types.filter(t => t.name === type)[0]?.description || type}
                                </span>
                                <i>{type}</i>
                            </button>
                        </a>
                    ))}
            </div>

            <Footer />
        </div>
    )
}

export const query = graphql`
    query {
        sanityArchiveSearch {
            archives {
                name
                shownoinfo
                type
                url
            }
            noinfo_questions
            types {
                description
                name
            }
        }
    }
`

export default ArchiveNoInfo
